import React from "react";
import l1 from "../assets/ll1.png";
import l2 from "../assets/ll2.png";
import l3 from "../assets/l3.png";
import l4 from "../assets/ll4.png";
import l5 from "../assets/ll5.png";
import l11 from "../assets/l11.png";
import l10 from "../assets/ll10.png";
import l9 from "../assets/l9.png";
import l8 from "../assets/ll7.png";
import l7 from "../assets/minecraft.png";
import l6 from "../assets/l6.png";
import p6 from "../assets/1.png";
import p7 from "../assets/2.png";
import p8 from "../assets/3.png";

export default function LogoCloud() {
  return (
    <div className="mx-auto lg:text-center">
      <div className="bg-white mt-10">
        <h1 className="text-xl font-semibold text-blue-600">Our Expertise</h1>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg font-semibold leading-8 text-gray-900"></h2>
          <div className="mx-auto mt-10 grid grid-cols-2 gap-6 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7">
            <img
              className="max-h-12 w-full object-contain"
              src={l1}
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l2}
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l3}
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l4}
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l5}
              alt="Statamic"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l6}
              alt="Statamic"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={p6}
              alt="Statamic"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={p8}
              alt="Statamic"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l7}
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l8}
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l9}
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l10}
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={l11}
              alt="Statamic"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain"
              src={p7}
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

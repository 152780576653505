import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import LOGO from '../assets/logo_nav.png';
import LOGO2 from '../assets/Logo.png';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About Us', href: '/about' },
  {
    name: 'Courses',
    href: '/courses',
    subPages: [
      { name: 'Computing Basics', href: '/courses/computing-basics' },
      { name: 'Coding And Game Design', href: '/courses/coding-and-game-design' },
      { name: 'Graphic Design', href: '/courses/graphic-design' },
      { name: 'Science', href: '/courses/science' },
      { name: 'Personal Finance', href: '/courses/personal-finance' },
      { name: 'Academic Support', href: '/courses/academic-support' },
    ]
  },
  { name: 'Contact us', href: '/contact' },
];

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const toggleSubmenu = (index) => {
    setSubmenuOpen(index);
    clearTimeout(hoverTimeout);
  };

  const closeSubmenu = () => {
    const timeout = setTimeout(() => {
      setSubmenuOpen(null);
    }, 1000);
    setHoverTimeout(timeout);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
    clearTimeout(hoverTimeout);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
    clearTimeout(hoverTimeout);
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50 bg-white ">
      <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-2 p-1.5">
            <span className="sr-only">SciTech Coders</span>
            <img
              className="h-12 w-auto"
              src={LOGO}
              alt="SciTech Coders Logo"
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={handleMobileMenuOpen}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item, index) => (
            <React.Fragment key={item.name}>
              {item.subPages ? (
                <div
                  className="relative group"
                  onMouseEnter={() => toggleSubmenu(index)}
                  onMouseLeave={closeSubmenu}
                >
                  <a
                    href={item.href}
                    className="text-sm font-semibold leading-6 text-gray-900 group relative flex items-center transition duration-300 ease-in-out hover:text-[#24ABF8] hover:underline-blue-500"
                  >
                    <span>{item.name}</span>
                    <ChevronDownIcon className="h-4 w-4 ml-1" aria-hidden="true" />
                  </a>
                  {submenuOpen === index && (
                    <div className="absolute bg-white py-1 mt-1 w-[200px] rounded-lg shadow-lg z-10" onMouseEnter={() => toggleSubmenu(index)} onMouseLeave={closeSubmenu}>
                      {item.subPages.map(subPage => (
                        <a
                          key={subPage.name}
                          href={subPage.href}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                        >
                          {subPage.name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900 transition duration-300 ease-in-out hover:text-blue-500 hover:underline-blue-500"
                >
                  {item.name}
                </a>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
          <a className="inline-block w-auto text-center font-semibold min-w-[100px] px-6 py-4 text-white transition-all rounded-md shadow-xl sm:w-auto bg-gradient-to-r from-blue-600 to-blue-500 hover:bg-gradient-to-b shadow-blue-200 hover:shadow-2xl hover:shadow-blue-600 hover:-tranneutral-y-px" href="https://www.cognitoforms.com/SciTechCodersLtd/SciTechCodersRegistrationForm">
            Enrol Now
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={handleMobileMenuClose}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SciTech Coders</span>
              <img className="h-12 w-auto" src={LOGO2} alt="SciTech Coders Logo" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={handleMobileMenuClose}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              {navigation.map((item, index) => (
                <React.Fragment key={item.name}>
                  {item.subPages ? (
                    <div className="group relative cursor-pointer py-2">
                      <div className="flex items-center justify-between space-x-5 bg-white px-4">
                        <a className="menu-hover text-base font-medium lg:mx-4" href="/courses">
                          Courses
                        </a>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                          </svg>
                        </span>
                      </div>
                      <div className="invisible absolute z-50 flex w-full flex-col bg-gray-100 py-1 px-4 text-gray-800 shadow-xl group-hover:visible">
                        {item.subPages.map(subPage => (
                          <a
                            key={subPage.name}
                            href={subPage.href}
                            className="my-2 block border-b border-gray-100 py-1 font-semibold text-gray-500 hover:text-black md:mx-2"
                          >
                            {subPage.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group relative cursor-pointer py-2 flex items-center justify-between space-x-5 bg-white px-4"
                    >
                      {item.name}
                    </a>
                  )}
                </React.Fragment>
              ))}
              <a className="inline-block w-auto text-center font-semibold min-w-[200px] px-6 py-4 text-white transition-all rounded-md shadow-xl sm:w-auto bg-gradient-to-r from-blue-600 to-blue-500 hover:bg-gradient-to-b shadow-blue-200 hover:shadow-2xl hover:shadow-blue-400 hover:-translate-y-px" href="#">
                Enrol Now
              </a>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Navbar;

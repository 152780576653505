const timelineData = [
  {
    Head: "WHEN",
    Heading1: "Classes Availability",
    descInDepth: "Classes are available both online and offline throughout the week. Different sessions can be booked according to the student's availability."
  },
  {
    Head: "WHAT",
    Heading1: "Course Outline",
    Heading2: "Maths Curriculum Overview",
    descInDepth: `
    Key Stage 1 (Reception to Year 2)
    • Numbers & Counting: Understanding numbers, counting sequences.
    • Addition & Subtraction: Basic operations and problem-solving.
    • Problem Solving: Simple word problems and logic puzzles.
    • Shapes: Identifying and describing basic shapes.
    Key Stage 2 (Years 3 to 6)
    • Addition & Subtraction: Advanced techniques and applications.
    • Multiplication & Division: Mastery of times tables and division facts.
    • Decimals & Fractions: Understanding and calculating with decimals and fractions.
    • Percentages: Basic concepts and calculations.
    • SATS Exam Practice: Preparation and practice for SATS exams.
    Key Stage 3 (Years 7 to 9)
    • Algebra: Introduction to variables, expressions, and equations.
    • Geometry: Understanding shapes, angles, and theorems.
    • Number: Advanced arithmetic and number theory.
    • Data Handling: Collecting, analyzing, and interpreting data.
    • GCSE (Years 10 & 11)
    • Exam Practice: Focused practice on past papers and exam techniques.
    • Higher & Foundation Topics: Covering all GCSE curriculum topics at different levels.
    • Further Algebra & Geometry: Complex equations and geometric problems.
    • Number Handling: Advanced number operations and applications.
    • Data Handling: In-depth analysis and interpretation of data sets.`
  },
  {
    Head: "WHY",
    Heading1: "Purpose of the Course",
    descInDepth: `• To develop a strong foundation in core academic subjects.
    • To enhance problem-solving and critical thinking skills.
    • To foster a lifelong interest in learning.
    • To prepare for advanced studies and exams.`
  },
  {
    Head: "HOW",
    Heading1: "Teaching Methodology",
    descInDepth: `• Interactive lessons with hands-on activities and projects.
    • Step-by-step guidance from experienced instructors.
    • Continuous feedback and support to track progress and enhance understanding.
    • Flexible learning options tailored to individual needs.`
  }
];

export default timelineData;

import { useState } from "react";
import timelineData from "./TimelineData";
import "./Timeline.css";

const Timeline = () => {
  const [active, setActive] = useState(0);

  const renderContent = (descInDepth) => {
    if (typeof descInDepth === 'string') {
      return <span>{descInDepth}</span>;
    }
    if (Array.isArray(descInDepth)) {
      return descInDepth.map((item, index) => (
        <span key={index}>
          {item}
          <br />
        </span>
      ));
    }
    if (typeof descInDepth === 'object' && descInDepth !== null) {
      if (descInDepth.sections) {
        return descInDepth.sections.map((section, index) => (
          <div key={index}>
            <strong>{section.title}</strong>
            <ul>
              {section.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          </div>
        ));
      }
      if (descInDepth.points) {
        return (
          <ul>
            {descInDepth.points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        );
      }
      if (descInDepth.methods) {
        return (
          <div>
            <ul>
              {descInDepth.methods.map((method, index) => (
                <li key={index}>{method}</li>
              ))}
            </ul>
            {descInDepth.conclusion && <p>{descInDepth.conclusion}</p>}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className="headers-timeline_main">
      <div className="headers-timeline">
        <div className="row-one">
          {timelineData.map((item, index) => (
            <div key={index} className="headers">
              <div
                className={`boxed ${active === index ? "active-timelineOne" : ""}`}
                onClick={() => setActive(index)}
              >
                <div className="time-head">{item.Head}</div>
                <div className={`${active === index ? "arrow-down" : ""}`}></div>
              </div>
              <div className={`${active === index ? "show-mob-time" : "hid-mob-time"}`}>
                <div className={`timeline-card timeline-card-mobile ${active === index ? "show-mob-time" : "hid-mob-time"}`}>
                  <div className="timeline-card-header">
                    <div className="col-1">{item.Heading1}</div>
                    {/* <div className="col-2">{item.Heading1Desc}</div> */}
                  </div>
                  <div>
                    <div className="timeline-card-body">
                      {renderContent(item.descInDepth)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="timeline-card timeline-card-xl">
          <div className="timeline-card-header">
            <div className="col-1">{timelineData[active].Heading1}</div>
            {/* <div className="col-2">{timelineData[active].Heading1Desc}</div> */}
          </div>
          <div>
            <div className="timeline-card-body">
              {renderContent(timelineData[active].descInDepth)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aboutus from '../src/pages/Aboutus'
import Program from './pages/Program';

import Contact from '../src/pages/Contact';
import Exploreprogram from './pages/ExploreProgram/Exploreprogram';
import Exploreprogram1 from './pages/ExploreProgram1';
import Exploreprogram2 from './pages/ExploreProgram2';
import Exploreprogram3 from './pages/ExploreProgram3';
import Exploreprogram4 from './pages/ExploreProgram4';
import Exploreprogram5 from './pages/ExploreProgram5';
import Exploreprogram6 from './pages/ExploreProgram6';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
       <Routes>
        <Route exact path="/" element={<App/>} />
        <Route exact path="/about" element={<Aboutus/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/courses" element={<Program/>} />
        <Route exact path="/courses/academic-support" element={<Exploreprogram1/>}/>
        <Route exact path="/courses/computing-basics" element={<Exploreprogram2/>}/>
        <Route exact path="/courses/coding-and-game-design" element={<Exploreprogram3/>}/>
        <Route exact path="/courses/graphic-design" element={<Exploreprogram4/>}/>
        <Route exact path="/courses/science" element={<Exploreprogram5/>}/>
        <Route exact path="/courses/personal-finance" element={<Exploreprogram6/>}/>     
      </Routes>
      </BrowserRouter>
);

reportWebVitals();

import React from 'react'
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Timeline from './info/Timeline'
import Exploreprogramhero from './Exploreprogramhero';
import TopNotchFeatures from './Topnotchfeatures';
import EnrollCTA from '../Program/EnrollCTA';

const Exploreprogram2 = () => {
  return (
    <>
    
    <Navbar/>
    <Exploreprogramhero/>
    <div className="mx-auto max-w-4xl pb-14 py-12 px-8 sm:px-4 lg:py-28 lg:text-center ">
          <p className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Enrol now to join our Computer Basics course and become one of the future tech innovators</p>
        </div>
    <Timeline/>



    <TopNotchFeatures/>

    <div className="bg-white py-6 sm:py-16 lg:py-10">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-y-12 sm:gap-y-16 lg:grid-cols-2 lg:gap-x-8">
          <div>
            <p className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              See the magic of our Computing Basics for Kids course
            </p>
          </div>
          <div className="flex justify-center sm:justify-start lg:justify-end">
                <div class="flex max-w-sm rounded-xl bg-gradient-to-tr from-pink-300 to-blue-300 p-0.5 shadow-lg">
                    <button class="flex-1 font-bold text-xl bg-white px-6 py-3 rounded-xl">Play Course Demo
                    </button>
                </div>
          </div>
        </div>
      </div>
    </div>
    <EnrollCTA/>
    <Footer/>
    </>
  )
}

export default Exploreprogram2
const timelineData = [
    {
      Head: "WHEN",
      Heading1: "Classes Availability",
      Heading1Desc: "Available both online and offline",
      descInDepth: [
        "Different sessions can be booked according to the student's availability."
      ]
    },
    {
      Head: "WHAT",
      Heading1: "Detailed Course Work",
      Heading1Desc: "Introduction to Scratch and Intermediate Coding.",
      descInDepth: {
        sections: [
          {
            title: "1. Introduction to Scratch",
            points: [
              "Maze Game: Create a character that navigates through a maze to reach a goal.",
              "Catch the Cat: Build a game where players catch a randomly moving cat.",
              "Pong Game: Develop a simple version of Pong using paddles and a bouncing ball.",
              "Platformer Game: Design a platformer game where the character jumps over obstacles and collects items."
            ]
          },
          {
            title: "Intermediate Coding",
            points: [
              "Python Basics:",
              "  - Syntax and Variables: Learn the basics of Python syntax and how to use variables.",
              "  - Loops and Conditionals: Implement loops for repeated actions and conditionals for decision-making.",
              "  - Simple Projects: Create basic applications such as a calculator or a number guessing game.",
              "HTML & CSS:",
              "  - Building Web Pages: Understand the structure of HTML and how to create web pages.",
              "  - Styling with CSS: Apply styles to web pages using CSS to enhance appearance.",
              "  - Projects: Create a personal portfolio site or a simple blog.",
              "Java-Script:",
              "  - Interactivity: Add interactive elements to web pages.",
              "  - Basic Functions and Event Handling: Learn to write functions and handle user events.",
              "  - Projects: Develop a simple interactive game or a dynamic web application."
            ]
          },
          {
            title: "Game and Logic Building",
            points: [
              "Advanced Scratch Projects:",
              "  - Complex Games: Develop more intricate games with multiple levels and complex logic.",
              "  - Examples: Create a racing game, an adventure game, or a simulation game.",
              "Introduction to Game Logic:",
              "  - Character Movement: Implement smooth character movements and controls.",
              "  - Scoring System: Develop a scoring system to track player progress.",
              "  - Level Design: Design different levels and challenges for the games."
            ]
          }
        ]
      }
    },
    {
      Head: "WHY",
      Heading1: "Purpose of the Course",
      Heading1Desc: "Key reasons to take the course.",
      descInDepth: [
        "Ignite creativity and enhance problem-solving skills.",
        "Build a solid foundation in coding and game design.",
        "Foster logical thinking and innovation.",
        "Prepare for advanced programming and game development concepts."
      ]
    },
    {
      Head: "HOW",
      Heading1: "Teaching Methodology",
      Heading1Desc: "Our approach to ensuring effective learning.",
      descInDepth: {
        methods: [
          "Interactive lessons with hands-on projects and coding exercises.",
          "Step-by-step guidance from experienced instructors.",
          "Project-based learning to apply concepts in real-world scenarios.",
          "Continuous feedback and support to track progress and enhance skills."
        ],
        conclusion: "This structured approach ensures that students gain essential skills in computing through engaging, interactive, and fun learning experiences."
      }
    }
  ];
  
  export default timelineData;
  
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import AboutHero from "../assets/aboutus.svg";
import Aboutus2 from "../assets/AboutUs2.svg";
import "../Section.css";

const AboutUs = () => {
  useEffect(() => {
    // JavaScript to toggle the answers and rotate the arrows
    document
      .querySelectorAll('[id^="question"]')
      .forEach(function (button, index) {
        button.addEventListener("click", function () {
          var answer = document.getElementById("answer" + (index + 1));
          var arrow = document.getElementById("arrow" + (index + 1));

          if (answer.style.display === "none" || answer.style.display === "") {
            answer.style.display = "block";
            arrow.style.transform = "rotate(0deg)";
          } else {
            answer.style.display = "none";
            arrow.style.transform = "rotate(-180deg)";
          }
        });
      });
  }, []);

  return (
    <>
      <Navbar />

      <section className="pt-20 bg-white">
        <div className="about-header">
          <div className="mb-6 max-w-3xl justify-center items-center sm:text-center md:mx-auto md:mb-12">
            {/* <h2 className="font-heading mb-4 font-bold tracking-tight text-zinc-100 text-3xl sm:text-5xl">
              About Us
            </h2> */}
          </div>
        </div>
        <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
            <div className="max-w-lg">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                SciTech Coders
              </h2>
              <p className="mt-4 text-gray-600 text-lg">
                At SciTech Coders, we offer engaging and hands-on science and
                coding programs designed to inspire creativity and ignite a
                passion for innovation in kids of all ages. Join us on a journey
                where science and coding collide, unlocking a universe of
                endless possibilities and inspiring the innovators of tomorrow!
              </p>
            </div>
            <div className="mt-12 md:mt-0">
              <img
                src={AboutHero}
                alt="About Us Image"
                className="object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container px-6 py-10 mx-auto">
          <div className="mt-8 lg:-mx-6 lg:flex lg:items-center">
            <img
              className="object-cover w-full lg:mx-6 lg:w-1/2 rounded-xl h-30 lg:h-30"
              src={Aboutus2}
              alt="About Us"
            />
            <div className="max-w-lg">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                What we do
              </h2>
              <p className="mt-4 text-gray-600 text-lg">
                At SciTech Coders, we:
              </p>
              <ul className="list-disc">
                <li>
                  <b>Foster Creativity: </b>Through interactive projects, we
                  encourage kids to think creatively and solve problems.
                </li>
                <li>
                  <b>Teach Coding Skills: </b>Our programs cover various
                  programming languages and technologies, laying a strong
                  foundation for future tech enthusiasts.
                </li>
                <li>
                  <b>Explore Science Concepts: </b>We make science fun and
                  accessible, helping kids understand and enjoy scientific
                  principles.
                </li>
                <li>
                  <b>Promote Innovation: </b>By combining science and coding, we
                  empower kids to develop innovative solutions and think outside
                  the box.
                </li>
                <li>
                  <b>Academic Support: </b>Tailored programs in Maths, Science,
                  and English for Key Stage 1-3, GCSE, and A-Level students.
                  Also, Arabic language study programs.
                </li>
                <li>
                  <b>Teach Money Management: </b>Introduce kids to essential
                  financial concepts, including saving, budgeting, and
                  investing.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Frequently Asked Questions
            </h2>
          </div>
          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                id="question1"
                data-state="closed"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="flex text-lg font-semibold text-black">
                  Who can join our courses?
                </span>
                <svg
                  id="arrow1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="answer1"
                style={{ display: "none" }}
                className="px-4 pb-5 sm:px-6 sm:pb-6"
              >
                <p>
                  Our courses are designed for kids of all ages and skill
                  levels. Whether your child is a beginner or has some
                  experience, we have a program that will suit their needs!
                </p>
              </div>
            </div>
            <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                id="question2"
                data-state="closed"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="flex text-lg font-semibold text-black">
                  What makes our courses unique?
                </span>
                <svg
                  id="arrow2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="answer2"
                style={{ display: "none" }}
                className="px-4 pb-5 sm:px-6 sm:pb-6"
              >
                <p>
                  Our courses combine science, coding, and financial literacy to
                  provide a holistic educational experience. We emphasise
                  hands-on learning and real-world applications to keep kids
                  engaged and inspired.
                </p>
              </div>
            </div>
            <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                id="question3"
                data-state="closed"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="flex text-lg font-semibold text-black">
                  How are our courses structured?
                </span>
                <svg
                  id="arrow3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="answer3"
                style={{ display: "none" }}
                className="px-4 pb-5 sm:px-6 sm:pb-6"
              >
                <p>
                  Our courses are structured in a way that builds on knowledge
                  progressively. We offer a range of courses from introductory
                  to advanced levels, ensuring continuous growth and learning.
                </p>
              </div>
            </div>
            <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                id="question4"
                data-state="closed"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="flex text-lg font-semibold text-black">
                  What kind of support do we offer?
                </span>
                <svg
                  id="arrow4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="answer4"
                style={{ display: "none" }}
                className="px-4 pb-5 sm:px-6 sm:pb-6"
              >
                <p>
                  We offer personalised support to all our students. Our
                  instructors are always available to provide guidance, answer
                  questions, and help with any challenges.
                </p>
              </div>
            </div>
            <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                id="question4"
                data-state="closed"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="flex text-lg font-semibold text-black">
                  How can parents get involved?
                </span>
                <svg
                  id="arrow5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="answer5"
                style={{ display: "none" }}
                className="px-4 pb-5 sm:px-6 sm:pb-6"
              >
                <p>
                  We encourage parents to stay engaged with their child's
                  learning journey. We provide regular updates on progress and
                  opportunities for parents to participate in events and
                  workshops.
                </p>
              </div>
            </div>
            <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
              <button
                type="button"
                id="question4"
                data-state="closed"
                className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
              >
                <span className="flex text-lg font-semibold text-black">
                  What are the outcomes of our courses?
                </span>
                <svg
                  id="arrow6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <div
                id="answer6"
                style={{ display: "none" }}
                className="px-4 pb-5 sm:px-6 sm:pb-6"
              >
                <p>
                  Our students gain practical skills in coding and science,
                  develop creative problem-solving abilities, and build a strong
                  foundation in financial literacy, preparing them for future
                  academic and career success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AboutUs;

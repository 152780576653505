import { PencilIcon } from "@heroicons/react/20/solid";
import { LinkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import LOGO_NAV from "../assets/Logo.png";
import LOGO from "../assets/logo_nav.png";
import Youtube from "../assets/youtube.svg";
import Twitter from "../assets/twitter.svg";
import Instagram from "../assets/instagram.svg";
import pin from "../assets/address.svg";
import mail from "../assets/mail.svg";
import phone from "../assets/phone.svg";
import { IoHomeOutline } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { IoIosCall } from "react-icons/io";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTnCModalOpen, setIsTnCModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto"; // Allow scrolling when modal is closed
  };

  const openTnCModal = () => {
    setIsTnCModalOpen(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
  };

  const closeTnCModal = () => {
    setIsTnCModalOpen(false);
    document.body.style.overflow = "auto"; // Allow scrolling when modal is closed
  };
  // #24ABF8
  // #019CF8
  return (
    <>
      <div className="relative isolate overflow-hidden bg-zinc-100 py-16 sm:py-10 lg:py-15">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg">
              {/* <h2 className="text-3xl font-bold tracking-tight text-zinc-900 sm:text-4xl">SciTech Coders</h2> */}
              <img className="h-24 w-auto" src={LOGO_NAV} alt="" />

              <div className="flex flex-col pt-6 items-start">
                <div className="font-bold text-xl underline">Contact Us</div>
                <dt className="mt-4 flex font-medium text-zinc-900 items-center	">
                  <IoIosCall />{" "}
                  <span className="px-2">
                    <a href="tel:03337723137"> 03337723137</a> /{" "}
                    <a href="tel:07821129376"> 07821129376 </a>
                  </span>
                </dt>
                <dt className="mt-4 flex font-medium text-zinc-900 items-center	">
                  {" "}
                  <IoMdMail />
                  <span className="px-2">
                    <a href="mailto:info@scitechcoders.co.uk">
                      {" "}
                      info@scitechcoders.co.uk
                    </a>{" "}
                  </span>
                </dt>
                <dt className="mt-4 flex font-medium text-zinc-900 items-center	">
                  {" "}
                  <IoHomeOutline />
                  <span className="px-2">
                    {" "}
                    98 Gibbins Road (Ground Floor), Stratford, London, E15 2HU{" "}
                  </span>
                </dt>
              </div>

              <ul class="flex mt-5 space-x-5">
                <li>
                  <a
                    href="#"
                    class="text-zinc-900 hover:text-gray-900 dark:hover:text-zinc-900 dark:text-gray-400"
                  >
                    <svg
                      class="w-8 h-8"
                      fill="black"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-zinc-900 hover:text-gray-900 dark:hover:text-zinc-900 dark:text-gray-400"
                  >
                    <svg
                      class="w-8 h-8"
                      fill="black"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-zinc-900 hover:text-gray-900 dark:hover:text-zinc-900 dark:text-gray-400"
                  >
                    <img className="h-8 w-8" src={Youtube} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="text-gray-500 hover:text-black dark:hover:text-zinc-900 dark:text-gray-400"
                  >
                    <img className="h-7 w-7" src={Twitter} alt="" />
                  </a>
                </li>
                {/* <li>
                        <a href="#" class="text-gray-500 hover:text-black dark:hover:text-zinc-900 dark:text-gray-400">
                        <img
                                className="h-7 w-7"
                                src={Instagram}
                                alt=""
                              />
                        </a>
                    </li> */}
              </ul>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-3 lg:pt-2">
              <div className="flex flex-col items-start">
                <div className="font-bold text-xl text-zinc-900 underline">
                  Our Courses
                </div>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/course/computing-basics">Computing Basics</a>
                </dt>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/course/coding-and-game-design">
                    Coding and Game Design
                  </a>
                </dt>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/course/graphic-design">Graphic Design</a>
                </dt>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/course/science">Science</a>
                </dt>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/course/personal-finance">Personal Finanace</a>
                </dt>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/course/academic-support">Academics Support</a>
                </dt>
              </div>

              <div className="flex flex-col items-start">
                <div className="font-bold text-xl text-zinc-900 underline">
                  Pages
                </div>
                <dt className="mt-4 font-medium text-zinc-900 ">
                  <a href="/">Home</a>
                </dt>
                <dt className="mt-4 font-semibold text-zinc-900 ">
                  <a href="/course">Courses</a>
                </dt>
                <dt className="mt-4 font-semibold text-zinc-900 ">
                  <a href="/about">About Us</a>
                </dt>
                <dt className="mt-4 font-semibold text-zinc-900 ">
                  <a href="/contact">Contact Us</a>
                </dt>
              </div>

              <div className="flex flex-col items-start">
                <div className="font-bold text-xl text-zinc-900 underline">
                  Quick Links
                </div>
                {/* <dt className="mt-4 font-medium text-zinc-900 "><button onClick={openTnCModal} >Terms & Conditions</button></dt> */}
                <dt className="mt-4 font-semibold text-zinc-900 ">
                  <button onClick={openModal}>Privacy Policy</button>
                </dt>
                <dt className="mt-4 font-semibold text-zinc-900 ">
                  <a href="https://www.cognitoforms.com/SciTechCodersLtd/SciTechCodersRegistrationForm">
                    Enrol Now
                  </a>
                </dt>
              </div>
            </dl>
          </div>
        </div>

        <hr />

        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
          aria-hidden="true"
        >
          <div
            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffffff] to-[#ffffff] opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center w-full mx-auto bg-[#005AE0] grid py-5 lg:flex-row px-10 ">
        <p className="text-sm text-white text-center">
          © Copyright 2024 SciTech Coders (STC) Ltd. All rights reserved.
        </p>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-60 flex justify-center items-center">
          <div className="relative bg-zinc-900 max-w-lg rounded-lg p-8">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Content */}
            <h2 className="text-xl font-semibold mb-4 text-white">
              Privacy Policy
            </h2>
            <p className="text-white">
              Your privacy is important to us. Get in touch to know more about
              our Privacy Policy.
              {/* Add your privacy policy text here */}
            </p>
          </div>
        </div>
      )}

      {/* Terms & Condition Modals */}
      {isTnCModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-60 flex justify-center items-center">
          <div className="relative bg-zinc-900 max-w-lg rounded-lg p-8">
            {/* Close Button */}
            <button
              onClick={closeTnCModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Modal Content */}
            <h2 className="text-xl font-semibold mb-4 text-white">
              Terms and Conditions
            </h2>
            <p className="text-black">
              Your privacy is important to us. This is our privacy policy. Add
              your privacy policy text here
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;

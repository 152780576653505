const timelineData = [
    {
      Head: "WHEN",
      Heading1: "Classes Availability",
      Heading1Desc: "Classes are available both online and offline throughout the week.",
      descInDepth:"Different sessions can be booked according to the student's availability."
      
    },
    {
      Head: "WHAT",
      Heading1: "Course Outline",
      Heading1Desc: "Structured content for different key stages.",
      descInDepth: {
        sections: [
          {
            title: "Key Stage 1 (Ages 5-7)",
            subjects: [
              {
                subject: "Chemistry",
                experiments: ["-Mixing colors to observe reactions.", 
                            "-Safe handling of household substances."],
                topics: ["Basic properties of materials.", "Understanding states of matter (solid, liquid, gas).", "Simple mixtures and solutions."]
              },
              {
                subject: "Physics",
                experiments: ["-Simple demonstrations of motion using toys.", "-Light and shadows with torches and objects."],
                topics: ["Gravity basics.", "Sound and vibrations.", "Exploring magnets."]
              },
              {
                subject: "Biology",
                experiments: ["Growing bean plants.", "Observing the life cycle of a butterfly."],
                topics: ["Basic human anatomy.", "Identifying plants and animals.", "Life cycles of different organisms."]
              },
              {
                subject: "Maths",
                experiments: ["Counting games.", "Basic addition and subtraction."],
                topics: ["Shapes and patterns.", "Simple measurements.", "Introduction to time and money."]
              }
            ]
          },
          {
            title: "Key Stage 2 (Ages 7-11)",
            subjects: [
              {
                subject: "Chemistry",
                experiments: ["Mixing vinegar and baking soda.", "Creating a simple salt crystal garden."],
                topics: ["Understanding acids and bases.", "Introduction to chemical reactions.", "Exploring the properties of materials."]
              },
              {
                subject: "Physics",
                experiments: ["Building and testing simple levers and pulleys.", "Experimenting with magnets and paperclips."],
                topics: ["Forces and motion.", "Basic electricity and circuits.", "Understanding energy and its forms."]
              },
              {
                subject: "Biology",
                experiments: ["Dissecting a flower to study its parts.", "Observing microorganisms in pond water."],
                topics: ["Plant and animal biology.", "Simple ecosystems.", "Human body systems."]
              },
              {
                subject: "Maths",
                experiments: ["Multiplication games.", "Division tricks and shortcuts."],
                topics: ["Fractions and decimals.", "Basic geometry.", "Introduction to data handling."]
              }
            ]
          },
          {
            title: "Key Stage 3 (Ages 11-14)",
            subjects: [
              {
                subject: "Chemistry",
                experiments: ["Investigating the reactivity of metals.", "Creating and observing endothermic and exothermic reactions."],
                topics: ["Atomic structure.", "The periodic table.", "Introduction to organic chemistry."]
              },
              {
                subject: "Physics",
                experiments: ["Building circuits with switches and lights.", "Studying the principles of buoyancy with different objects."],
                topics: ["Energy transfer.", "Waves and their properties.", "Newton's laws of motion."]
              },
              {
                subject: "Biology",
                experiments: ["Analyzing plant responses to light.", "Investigating human pulse rates under different conditions."],
                topics: ["Cell biology.", "Human body systems.", "Introduction to genetics."]
              },
              {
                subject: "Maths",
                experiments: ["Algebra puzzles.", "Rapid mental calculations."],
                topics: ["Equations and inequalities.", "Data handling and statistics.", "Geometry and measurement."]
              }
            ]
          },
          {
            title: "Key Stage 4 (Ages 14-16)",
            subjects: [
              {
                subject: "Chemistry",
                experiments: ["Synthesis and analysis of simple organic compounds.", "Investigating rates of reaction."],
                topics: ["Organic chemistry.", "Chemical bonding.", "Thermodynamics."]
              },
              {
                subject: "Physics",
                experiments: ["Investigating the properties of waves.", "Practical applications of electromagnetism."],
                topics: ["Electricity and magnetism.", "Mechanics and dynamics.", "Thermodynamics."]
              },
              {
                subject: "Biology",
                experiments: ["Genetic crosses and probability.", "Measuring respiration rates in different organisms."],
                topics: ["Genetics and evolution.", "Advanced human physiology.", "Ecology and environmental science."]
              },
              {
                subject: "Maths",
                experiments: ["Solving complex equations mentally.", "Geometry tricks and proofs."],
                topics: ["Advanced algebra.", "Calculus basics.", "Trigonometry and advanced geometry."]
              }
            ]
          }
        ]
      }
    },
    {
      Head: "WHY",
      Heading1: "Purpose of the Course",
      Heading1Desc: "Key reasons to take the course.",
      descInDepth: {
        points: [
          "To develop a strong foundation in scientific principles.",
          "To enhance problem-solving and critical thinking skills.",
          "To foster a lifelong interest in science and mathematics.",
          "To prepare for advanced studies in scientific fields."
        ]
      }
    },
    {
      Head: "HOW",
      Heading1: "Teaching Methodology",
      Heading1Desc: "Our approach to ensuring effective learning.",
      descInDepth: {
        points: [
          "Interactive lessons with hands-on experiments and activities.",
          "Step-by-step guidance from experienced instructors.",
          "Project-based learning to apply concepts in real-world scenarios.",
          "Continuous feedback and support to track progress and enhance understanding."
        ],
        conclusion: "This structured approach ensures that students gain essential skills in computing through engaging, interactive, and fun learning experiences."
      }
    }
  ];
  
  export default timelineData;
  
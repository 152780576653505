import React from 'react';
import AcademicLogo from '../../assets/academy.svg'
import ComputerLogo from '../../assets/computer.svg'
import CodingLogo from '../../assets/coding.svg'
import ScienceLogo from '../../assets/science.svg'
import TutorLogo from '../../assets/tutor.svg'
import GraphicsLogo from '../../assets/graphics.svg'

const Card = () => {
  return (
    <>

<div className="mx-auto max-w-4xl px-8 sm:px-4 lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-red-600">Our Expertise</h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Courses
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          At SciTech Coders, we offer engaging and hands-on science and coding programmes designed to inspire creativity and ignite a passion for innovation in kids of all ages
          </p>
        </div>


      {/* {Card 1} */}
      
    <div className="mx-auto max-w-7xl px-6 pt-6 lg:px-8">

    <div className="justify-center grid grid-cols-1 gap-x-2 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-2 ">
    

    {/* {Card 2} */}
    <a href="/courses/computing-basics" >
    <div className="h-[600px] flex-col justify-center overflow-hidden bg-white">
      <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-red-500 transition-all duration-300 group-hover:scale-[12]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-red-500 transition-all duration-300 group-hover:bg-red-400">
          <img
                className="h-14 w-auto"
                src={ComputerLogo}
                alt=""
              />
          </span>
          
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Computing Basics 
              
            </p>
            </div>
          <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <p>Introduce your child to the world of computing with our tailored curriculum. We cover everything from the fundamentals of hardware and software to safe digital navigation. Equip your young learner with the essential skills for a digital future.</p>
          </div>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Key Stage 1 - 2 students
              
            </p>
          </div>
          <div className="pt-5 text-base font-semibold leading-7">
            <p>
              <p className="text-red-500 transition-all duration-300 group-hover:text-white">
              Explore Computer Basics &rarr;
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
    </a>

    {/* {Card 3} */}
    <a href="/courses/coding-and-game-design" >
    <div className=" h-[600px] flex-col justify-center overflow-hidden bg-white">
      <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-yellow-500 transition-all duration-300 group-hover:scale-[12]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-yellow-500 transition-all duration-300 group-hover:bg-yellow-400">
          <img
                className="h-14 w-auto"
                src={CodingLogo}
                alt=""
              />
          </span>
         
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
           Coding and Game Design
              
            </p>
            </div>
          <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <p>Ignite your child's creativity with our coding and game design classes. From creating their first lines of code to developing their own interactive games, our curriculum grows with your child's skills. Suitable for students from Key Stage 2-5.</p>
          </div>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Key Stage 2 - 5 students
              
            </p>
          </div>
          <div className="pt-5 text-base font-semibold leading-7">
            <p>
              <p className="text-yellow-500 transition-all duration-300 group-hover:text-white">
              Discover Coding and Game Design &rarr;
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
    </a>
    <a href="/courses/graphic-design">
    <div className="h-[600px] flex-col justify-center overflow-hidden bg-white">
    
      <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-sky-500 transition-all duration-300 group-hover:scale-[12]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-sky-500 transition-all duration-300 group-hover:bg-sky-400">
          <img
                className="h-14 w-auto"
                src={GraphicsLogo}
                alt=""
              />
          </span>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Graphic Design
              
            </p>
            </div>
          <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <p>Unlock your child's artistic potential with our graphic design classes. From mastering design principles to creating stunning digital artwork, our courses are perfect for students in Key Stage 3 to Key Stage 5 who have a passion for visual creativity.</p>
          </div>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Key Stage 3-5 students
              
            </p>
          </div>
          <div className="pt-5 text-base font-semibold leading-7">
            <p>
              <p className="text-sky-500 transition-all duration-300 group-hover:text-white">
              Explore Graphic Design &rarr;
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
    </a>

    </div>
   
    </div>

    {/* {Card Row 2} */}

    <div className="mx-auto max-w-7xl px-6 py-2 lg:px-8">

    <div className="justify-center grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-2 ">
    
    {/* {Card 4} */}

{/* {Card 5} */}
    <a href="/courses/science">
    <div className="h-[600px] flex-col justify-center overflow-hidden bg-white">
      <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-red-500 transition-all duration-300 group-hover:scale-[12]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-red-500 transition-all duration-300 group-hover:bg-red-400">
          <img
                className="h-14 w-auto"
                src={ScienceLogo}
                alt=""
              />
          </span>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Science (Physics, Chemistry, Biology, Maths)
              
            </p>
            </div>
          <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <p>
            Dive into the wonders of science with our comprehensive science classes. Covering subjects such as physics, chemistry, biology, and maths, our curriculum is designed to spark curiosity and foster a love for learning.</p>
          </div>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            All Key Stage Students.
              
            </p>
          </div>
          <div className="pt-5 text-base font-semibold leading-7">
            <p>
              <p className="text-red-500 transition-all duration-300 group-hover:text-white">
              Discover Science programmes &rarr;
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
    </a>

    {/* {Card 6} */}
    <a href="/courses/personal-finance">
    <div className="h-[600px] flex-col justify-center overflow-hidden bg-white">
      <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-yellow-500 transition-all duration-300 group-hover:scale-[12]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-yellow-500 transition-all duration-300 group-hover:bg-yellow-400">
          <img
                className="h-14 w-auto"
                src={TutorLogo}
                alt=""
              />
          </span>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Personal Finance for Kids and Teenagers
              
            </p>
            </div>

          <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <p>Unlock essential money management skills with our Personal Finance Course for Key Stage 3 to Key Stage 5 students. From budgeting to investing, our program teaches crucial financial literacy to prepare young learners for a successful future.</p>
          </div>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Key Stage 3-5 students
              
            </p>
          </div>
          <div className="pt-5 text-base font-semibold leading-7">
            <p>
              <p className="text-yellow-500 transition-all duration-300 group-hover:text-white">
              Empower Your Child with Key Financial Skills &rarr;
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
    </a>

    <a href="/courses/academic-support">
    <div className="h-[600px] flex-col justify-center overflow-hidden bg-white">
    
      <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
        <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-sky-500 transition-all duration-300 group-hover:scale-[12]"></span>
        <div className="relative z-10 mx-auto max-w-md">
          <span className="grid h-20 w-20 place-items-center rounded-full bg-sky-500 transition-all duration-300 group-hover:bg-sky-400">
          <img
                className="h-14 w-auto"
                src={AcademicLogo}
                alt=""
              />
          </span>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Academic Support
              
            </p>
            </div>
          <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
            <p>Our tailored support programs enhance skills in Maths, Science, English, and Arabic for Key Stage 1 to Key Stage 3 students. We also offer comprehensive GCSE and A-Level preparation. Elevate your child's learning with expert guidance.</p>
          </div>
          <div className="pt-5 text-base font-bold leading-7 group-hover:text-white/90">
            <p>
            Key Stage 1-3, GCSE, A-Level students, and Arabic language learners
              
            </p>
          </div>
          <div className="pt-5 text-base font-semibold leading-7">
            <p>
              <p className="text-sky-500 transition-all duration-300 group-hover:text-white">
              Discover Acdemic Support &rarr;
              </p>
            </p>
          </div>
        </div>
      </div>

     

    </div>
    </a>
    </div>
    
    </div>



    
    </>
  );
};

export default Card;

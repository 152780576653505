import React from "react";
import bannerImage from "../assets/banner.png";
const Hero = () => {
  return (
    <>
      <div
        className="overflow-hidden py-16 sm:py-24 lg:py-32 sm:bg-center"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "left center",
        }}
      >
        <div className="mx-auto max-w-8xl px-6 py-10 md:px-12 md:py-16">
          <h1 className="text-3xl w-full font-semibold md:text-4xl lg:text-5xl mt-10 mb-8 sm:w-1/2">
            Inspiring
            <span className="text-white px-1 rounded-lg"> Young Minds </span> to
            <span className="text-white px-1 rounded-lg"> Code</span>,
            <span className="text-white px-1 rounded-lg"> Create</span>, and{" "}
            <span className="text-white px-1 rounded-lg">Change</span>
            <span> the World with </span>
            <span className="text-white px-1 rounded-lg">Science</span>
            <span>,</span>
            <span className="text-white px-1 rounded-lg">Technology</span>
            <span> and </span>
            <span className="text-white px-1 rounded-lg">Personal Finance</span>
          </h1>
          <p className="mt-4 text-lg w-full font-medium leading-relaxed text-gray-100 md:text-xl sm:w-1/2 ">
            Welcome to the intersection of Coding, Science, and Finance at
            SciTech Coders! Ignite your curiosity, master the languages of
            technology, and explore the wonders of STEM and financial literacy.
          </p>
          <div className="mt-8 flex gap-2">
            <a href="/courses">
              <button className="text-md rounded-lg bg-gradient-to-br from-white to-gray-200 px-4 py-[10px] font-semibold text-blue-600 hover:bg-gradient-to-tl hover:bg-yellow-500">
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                    className="mr-2"
                  >
                    <path d="M232.49,215.51,185,168a92.12,92.12,0,1,0-17,17l47.53,47.54a12,12,0,0,0,17-17ZM44,112a68,68,0,1,1,68,68A68.07,68.07,0,0,1,44,112Z" />
                  </svg>
                  Explore our courses
                </div>
              </button>
            </a>
          </div>
        </div>

        {/* <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffffff] to-[#ffffff] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div> */}
      </div>
    </>
  );
};

export default Hero;

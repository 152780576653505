const timelineData = [
    {
      Head: "WHEN",
      Heading1: "Classes Availability",
      Heading1Desc: "Available both online and offline",
      descInDepth: "Classes are available both online and offline throughout the week. Different sessions can be booked according to the student's availability."
    },
    {
      Head: "WHAT",
      Heading1: "Detailed Course Work",
      Heading1Desc: "Basic Financial Principles:",
      descInDepth: `
        Understanding money and its value
        Introduction to banking and financial institutions
        
        Budgeting:
        Creating and managing a personal budget
        Distinguishing between needs and wants
        
        Saving:
        Importance of saving money
        Different types of savings accounts
        
        Investing:
        Basics of investing and types of investments
        Understanding risk and return
      `
    },
    {
      Head: "WHY",
      Heading1: "Purpose of the Course",
      Heading1Desc: "To develop financial literacy and responsibility.",
      descInDepth: `
        To equip students with essential money management skills.
        To prepare students for making informed financial decisions.
        To foster a lifelong understanding of personal finance.
      `
    },
    {
      Head: "HOW",
      Heading1: "Teaching Methodology",
      Heading1Desc: "Interactive lessons with real-life financial scenarios.",
      descInDepth: `
        Step-by-step guidance from experienced instructors.
        Practical activities to apply financial concepts.
        Continuous feedback and support to track progress.
      `
    }
  ];
  
  export default timelineData;
  
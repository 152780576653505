const timelineData = [
    {
      Head: "WHEN",
      Heading1: "Classes Availability",
      // Heading1Desc: "Classes are available both online and offline throughout the week.",
      descInDepth: {
        details: [
          "Different sessions can be booked according to the student's availability."
        ]
      }
    },
    {
      Head: "WHAT",
      Heading1: "Course Outline",
      // Heading1Desc: "A structured approach to learning basic computing skills.",
      descInDepth: {
        sections: [
          {
            title: "1. Basics of Designing",
            points: [
              "Understanding design principles such as balance, contrast, and hierarchy."
            ]
          },
          {
            title: "2. Understanding Different Concepts",
            points: [
              "Learning about color theory, typography, and layout design."
            ]
          },
          {
            title: "3. Using Canva",
            points: [
              "Creating posters, social media graphics, and presentations.",
              "Example Projects: Designing a school event poster, creating social media posts for a fictional brand."
            ]
          },
          {
            title: "4. Introduction to Adobe Illustrator",
            points: [
              "Basic tools and functions.",
              "Example Projects: Designing a logo, creating vector illustrations."
            ]
          }
        ]
      }
    },
    {
      Head: "WHY",
      Heading1: "Purpose of the Course",
      // Heading1Desc: "Key reasons to take the course.",
      descInDepth: {
        points: [
          "Unlock artistic potential and foster creativity.",
          "Develop a strong foundation in graphic design principles.",
          "Enhance digital literacy and technical skills.",
          "Prepare for advanced design projects and potential future careers."
        ]
      }
    },
    {
      Head: "HOW",
      Heading1: "Teaching Methodology",
      // Heading1Desc: "Our approach to ensuring effective learning.",
      descInDepth: {
        methods: [
          "Interactive lessons with hands-on projects and design exercises.",
          "Step-by-step guidance from experienced instructors.",
          "Project-based learning to apply concepts in real-world scenarios.",
          "Continuous feedback and support to track progress and enhance skills."
        ],
        conclusion: "This structured approach ensures that students gain essential skills in computing through engaging, interactive, and fun learning experiences."
      }
    }
  ];
  
  export default timelineData;
  
import React from "react";
import reviewone from "../assets/Review_one.jpg";
import reviewtwo from "../assets/Review_two.jpg";
function Reviews() {
  return (
    <div className="py-20" id="reviews">
      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="mb-10 space-y-4 px-6 md:px-0">
          <h2 className="text-center text-2xl font-bold md:text-4xl">
            What our students say
          </h2>
        </div>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div className="p-8 border border-gray-200 rounded-3xl bg-white shadow-2xl shadow-gray-600/10">
            <div className="flex gap-4">
              <img
                className="w-12 h-12 rounded-full"
                src={reviewone}
                alt="user avatar"
                width="400"
                height="400"
                loading="lazy"
              />
              <div>
                <h6 className="text-lg font-medium text-gray-700">
                  Rafee Rahman
                </h6>
                <p className="text-sm text-gray-500">Student - Y12</p>
              </div>
            </div>
            <p className="mt-8">
              I recently completed a hybrid-mode course in graphic design with
              SciTech Coders, utilizing tools like Canva, and it has been a
              transformative experience. As a student, the flexibility of the
              course allowed me to balance my studies while gaining new skills.
              The knowledge I acquired, especially in using Canva, has equipped
              me to create professional posters, social media posts, and
              presentations, significantly enhancing my coursework and personal
              projects. SciTech Coders has given me practical tools to make my
              ideas visually compelling, and I am excited about the future
              opportunities this will bring. Thank you, SciTech Coders, for a
              fantastic learning experience!
            </p>
          </div>
          <div className="p-8 border border-gray-200 rounded-3xl bg-white shadow-2xl shadow-gray-600/10">
            <div className="flex gap-4">
              <img
                className="w-12 h-12 rounded-full"
                src={reviewtwo}
                alt="user avatar"
                width="200"
                height="200"
                loading="lazy"
              />
              <div>
                <h6 className="text-lg font-medium text-gray-700">
                  Musa Bin Ali Sajjad
                </h6>
                <p className="text-sm text-gray-500">Student - Y12</p>
              </div>
            </div>
            <p className="mt-8">
              I recently completed a course in computing basics with SciTech
              Coders. It was an amazing experience where I got to create some
              fun Scratch games and learn about logic building. I also learned a
              lot about the basics of computing, including hardware and
              software. The course was super fun and helped me understand how
              computers work. I am really excited to use what I
              learned in the future!
            </p>
          </div>
          <div className="p-8 border border-gray-200 rounded-3xl bg-white shadow-2xl shadow-gray-600/10">
            <div className="flex gap-4">
              <img
                className="w-12 h-12 rounded-full"
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt="user avatar"
                width="400"
                height="400"
                loading="lazy"
              />
              <div>
                <h6 className="text-lg font-medium text-gray-700">
                  Zara Tasnim
                </h6>
                <p className="text-sm text-gray-500">Student - Y7</p>
              </div>
            </div>
            <p className="mt-8">
              As a student, I recently completed an offline course in coding and
              game development with SciTech Coders, and it has significantly
              enhanced my skills. Before this course, I only had a basic
              knowledge of coding, but SciTech Coders helped me deepen my
              understanding and proficiency. I learned Python and the
              fundamentals of game development, which are skills that I am
              confident will benefit my future career. The hands-on, in-person
              instruction was invaluable, and I am grateful for the
              comprehensive education I received. Thank you, SciTech Coders, for
              an excellent learning experience!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;

import React from "react";
import "../../Section.css";

const ProgramHeader = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden courses-header py-16 sm:py-24 lg:py-32 ">
        <div className="mx-auto max-w-7xl px-6 py-14 lg:px-8">
          <div className="mx-auto ">
            <div className="max-w-6xl pt-4 lg:max-w-8xl">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Welcome to SciTech Coders - Empowering Young Minds Through
                Dynamic Online Learning!{" "}
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-50">
                Discover a world of knowledge and growth with our engaging
                online courses designed for young learners. Our platform offers
                interactive and fun educational experiences that inspire
                curiosity and foster development. Join us on this exciting
                journey of discovery and empower your child's future today!.
              </p>
            </div>
          </div>
        </div>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
          aria-hidden="true"
        >
          <div
            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffffff] to-[#ffffff] opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ProgramHeader;

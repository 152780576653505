const timelineData = [
    {
      Head: "WHEN",
      Heading1: "Classes Availability",
      descInDepth: "Classes are available both online and offline throughout the week."
      
    },
    {
      Head: "WHAT",
      Heading1: "Course Outline",
      Heading2: "Computing Curriculum Overview",
      descInDepth: {
        sections: [
          {
            title: "1. Basics of Hardware and Software",
            points: [
              "Understanding Computer Components: Identify and understand the functions of basic computer parts such as the monitor, keyboard, mouse, and CPU.",
              "Basic Operations: Learn how to turn on/off a computer, use a mouse, open software applications, and save files."
            ]
          },
          {
            title: "2. Introduction to Scratch Jr and Scratch",
            points: [
              "Navigating the Interface: Familiarise with the layout, tools, and navigation of Scratch Jr for younger students and Scratch for older students.",
              "Creating Basic Projects: Start with simple projects to understand how to add characters (sprites), backgrounds, and sounds."
            ]
          },
          {
            title: "3. Building Simple Games and Animations",
            points: [
              "Designing Characters and Backgrounds: Use built-in tools to draw or select characters and backgrounds.",
              "Developing Interactive Stories: Combine multiple elements to create interactive stories where characters move, talk, and interact based on student-designed scripts."
            ]
          },
          {
            title: "4. Basic Coding Concepts",
            points: [
              "Sequences: Understand the concept of sequences as a series of steps that the computer follows in order.",
              "Loops: Learn how to use loops to repeat actions, such as making a character move continuously or repeat a sound.",
              "Variables: Introduce the concept of variables to store information like scores or player names.",
              "Conditionals: Use conditional statements to make decisions in the code, such as 'if-then' scenarios where an action occurs only if a certain condition is met."
            ]
          },
          {
            title: "Examples of Simple Games in Scratch",
            points: [
              "Maze Game: Students will create a character that moves through a maze to reach a goal while avoiding obstacles.",
              "Catch the Cat: In this game, players control a sprite to catch a randomly moving cat on the screen.",
              "Pong Game: A simple version of the classic Pong game where players use paddles to keep a ball in play.",
              "Platformer Game: Design a basic platformer where the character jumps over obstacles and collects items to score points."
            ]
          }
        ]
      }
    },
    {
      Head: "WHY",
      Heading1: "Purpose of the Course",
      Heading1Desc: "Key reasons to take the course.",
      descInDepth: {
        points: [
          "To develop foundational computing skills",
          "Enhance problem-solving abilities",
          "Foster creativity and logical thinking",
          "Prepare for advanced computing concepts"
        ]
      }
    },
    {
      Head: "HOW",
      Heading1: "Teaching Methodology",
      Heading1Desc: "Our approach to ensuring effective learning.",
      descInDepth: {
        methods: [
          "Interactive lessons with hands-on projects",
          "Step-by-step guidance in using Scratch Jr and Scratch",
          "Engaging activities to reinforce learning",
          "Continuous feedback and support from experienced instructors"
        ],
        conclusion: "This structured approach ensures that students gain essential skills in computing through engaging, interactive, and fun learning experiences."
      }
    }
  ];
  
  export default timelineData;
  
import { useState } from "react";
import timelineData from "./TimelineData";
import "./Timeline.css";

const Timeline = () => {
  const [active, setActive] = useState(0);

  const renderDescInDepth = (descInDepth) => {
    if (typeof descInDepth === "string") {
      return descInDepth.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    } else if (Array.isArray(descInDepth.sections)) {
      return descInDepth.sections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <h4>{section.title}</h4>
          <ul>
            {section.points.map((point, pointIndex) => (
              <li key={pointIndex}>{point}</li>
            ))}
          </ul>
        </div>
      ));
    } else if (Array.isArray(descInDepth.points)) {
      return (
        <ul>
          {descInDepth.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      );
    } else if (descInDepth.methods) {
      return (
        <div>
          <ul>
            {descInDepth.methods.map((method, index) => (
              <li key={index}>{method}</li>
            ))}
          </ul>
          <p>{descInDepth.conclusion}</p>
        </div>
      );
    } else if (Array.isArray(descInDepth.details)) {
      return (
        <ul>
          {descInDepth.details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className="headers-timeline_main">
      <div className="headers-timeline">
        <div className="row-one">
          {timelineData.map((data, index) => (
            <div className="headers" key={index}>
              <div
                className={`boxed ${active === index ? "active-timelineOne" : ""}`}
                onClick={() => setActive(index)}
              >
                <div className="time-head">{data.Head}</div>
                <div className={`${active === index ? "arrow-down" : ""}`}></div>
              </div>
              <div className={`${active === index ? "show-mob-time" : "hid-mob-time"}`}>
                <div className={`timeline-card timeline-card-mobile ${active === index ? "show-mob-time" : "hid-mob-time"}`}>
                  <div className="timeline-card-header">
                    <div className="col-1">{data.Heading1}</div>
                    {/* <div className="col-2">{data.Heading1Desc}</div> */}
                  </div>
                  <div>
                    <div className="timeline-card-body">
                      {renderDescInDepth(data.descInDepth)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="timeline-card timeline-card-xl">
          <div className="timeline-card-header">
            <div className="col-1">{timelineData[active].Heading1}</div>
            {/* <div className="col-2">{timelineData[active].Heading1Desc}</div> */}
          </div>
          <div>
            <div className="timeline-card-body">
              {renderDescInDepth(timelineData[active].descInDepth)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;

import { useState } from "react";
import timelineData from "./TimelineData";
import "./Timeline.css";

const Timeline = () => {
  const [active, setActive] = useState(0);

  return (
    <div className="headers-timeline_main">
      <div className="headers-timeline">
        <div className="row-one">
          {timelineData.map((item, index) => (
            <div key={index} className="headers">
              <div
                className={`boxed ${active === index ? "active-timelineOne" : ""}`}
                onClick={() => setActive(index)}
              >
                <div className="time-head">{item.Head}</div>
                <div className={`${active === index ? "arrow-down" : ""}`}></div>
              </div>
              <div className={`${active === index ? "show-mob-time" : "hid-mob-time"}`}>
                <div className={`timeline-card timeline-card-mobile ${active === index ? "show-mob-time" : "hid-mob-time"}`}>
                  <div className="timeline-card-header">
                    <div className="col-1">{item.Heading1}</div>
                  </div>
                  <div className="timeline-card-body">
                    <ul>{item.descInDepth.split("\n").map((x, i) => (
                      <span key={i}>
                        {x}
                        <br />
                      </span>
                    ))}</ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="timeline-card timeline-card-xl">
          <div className="timeline-card-header">
            <div className="col-1">{timelineData[active].Heading1}</div>
          </div>
          <div className="timeline-card-body">
            <div className="head-2">{timelineData[active].Heading2}</div>
            {timelineData[active].descInDepth.split("\n").map((x, i) => (
              <span key={i}>
                {x}
                <br />
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
